@import '~antd/dist/antd.css';
@import "./assets/styles/antd-overrides";
@import "./assets/styles/mainColors";
@import '~material-icons/iconfont/material-icons.scss';
@import "./assets/styles/fonts";

* {
  box-sizing: border-box;
  margin: 0;
  font-family: Regular, 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FAFBFC;

  &.ReactModal__Body--open {
    overflow: hidden;
  }
}

iframe {
  display: none;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

button {
  cursor: pointer;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }
}

table {
  width: 100%;
}

a {
  text-decoration: none;
  color: #1a1d1d !important;
}

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

label {
  display: block;
  font-size: 13px;

  font-weight: 500;
  color: #000000;
  margin-bottom: 8px;
  white-space: nowrap;
}

input, select {
  &:focus {
    outline: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: white !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.field-required {
  color: red;
}

.flex-wrapper-right {
  display: flex;
  justify-content: flex-end;
}

.swal-button--confirm {
  padding: 7px 19px;
  background: $brandColor;
  box-shadow: none !important;
}

.swal-button:not([disabled]) {
  &:hover, &:active {
    background-color: $brandColor;
  }
}

.swal-button--cancel {
  padding: 7px 19px;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;

  &:not([disabled]):hover {
    background-color: #e8e8e8;
  }

  &:active {
    background-color: #d7d7d7 !important;
  }
}

.swal-icon {
  margin: 0 auto;
}

.swal-icon--warning {
  border-color: $brandColor;
  -webkit-animation: pulseWarning .75s infinite alternate;
  animation: pulseWarning .75s infinite alternate;
}

.swal-icon--warning__body,
.swal-icon--warning__dot {
  background: $brandColor;
}

.flex-row {
  display: flex;
}

.ant-select.invalid .ant-select-selection {
  border: 1px solid red;
}

.visibility-toggle {
  label {
    margin-right: 8px;
  }
}

@keyframes pulseWarning {
  0% {
    border-color: $brandColor;
  }

  100% {
    border-color: $brandColor;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms linear;
}

.ReactModal__Overlay--after-open {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  bottom: 0;
  background: rgba(0, 0, 0, .35) !important;
  opacity: 1;

  .preview-modal-content {
    overflow: hidden !important;
  }
}

.se-toolbar {
  overflow: hidden;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.oc-fm--list-view__row--selected {
  background-color: $brandColor !important;
}

.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-modal {
    width: 100% !important;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    top: 0;

    .ant-modal-header, .ant-modal-footer, .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-content {
      position: static;
      border-radius: 8px;
    }
  }
}

.fixed-top-right {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  white-space: nowrap;
  z-index: 50;

  label {
    margin-right: 10px;
    display: inline-block;
  }
}

.required-badge {
  color: red;
}

.items-list-wrapper {
  margin-top: 50px;
}

@media screen and (max-width: 1100px) {
  .items-list-wrapper .items-list-header {
    width: 850px !important;
  }
  .list-item-wrapper {
    width: 850px !important;
  }
}

@media screen and (max-width: 800px) {
  .items-list-wrapper .items-list-header {
    width: 750px !important;
  }
  .list-item-wrapper {
    width: 750px !important;
  }
}

.list-label {
  font-size: 24px;
  margin-top: 40px;
}

.exel-file {
  display: flex;
  align-items: end;
  justify-content: flex-end;
  padding-top: 40px;

  span {
    button {
      background-color:$brandColor;
      color: white;
      border-radius: 6px;
      padding: 10px 24px;
    }
  }
}

.type-block {
  margin: 40px 0 20px;
  color:$textColor;
  font-weight: 500;
  span{
    font-weight: 600;
    color:$textColor;
  }
}

.label-and-action{
  margin-top: 20px;
}
.file-wrapper{
  margin-top: 20px;
}
.contract-wrapper{
  margin-bottom: 20px;
}
