:root {
  --brandColor: #FE2F2F;
  --brandSecColor: #EBECF0;
}

$brandColor: var(--brandColor);
$brandSecColor: var(--brandSecColor);
$borderColor: #DBDFE3;
$textColor: #101828;

$badgeColor: #FF3939;
$confirmButtonColor: #FF9000;
$blueButtonColor: #0073F2;
$blueColor: #0073F2;

$inputBorderColor: #868686;
$inputTextColor: #1D1C1D;

$borderGreyColor: #e5e5e5;
$darkBlueColor: #10233C;
$greyBorderColor: #E6E8EA;
$lightGreyColor: #92969B;
